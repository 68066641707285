import React, { useState, useEffect } from "react"
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem } from "reactstrap"
import { Link } from "gatsby"
import logo from "./../img/Logo_Humero_Small.png"
import logoWhite from "./../img/Logo_Humero_Small_white.png"

const NavigationBar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [yPosition, setYPosition] = useState(0)
  const toggle = () => setIsOpen(!isOpen)

  useEffect(() => {
    window.onscroll = () => setYPosition(window.pageYOffset)
  })

  return <Navbar light={yPosition <= 5} dark={yPosition > 5} expand="lg" fixed="top">
    <Container>
      <NavbarBrand className="d-lg-none" href="/"><img src={yPosition > 5 ? logo : logoWhite}
                                                       alt="El Humero"/></NavbarBrand>
      <NavbarToggler onClick={toggle}/>
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <Link to="/" className="nav-link">About Us</Link>
          </NavItem>
          <NavItem>
            <Link to="/menu/" className="nav-link">Menu</Link>
          </NavItem>
          <NavItem>
            <Link to="/reservation/" className="nav-link">Reservation</Link>
          </NavItem>
        </Nav>
        <NavbarBrand href="/"><img src={yPosition > 5 ? logo : logoWhite} alt="El Humero"/></NavbarBrand>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <a href="https://order.elhumero.co.nz/" className="nav-link">Takeaway Orders</a>
          </NavItem>
          <NavItem>
            <Link to="/" className="nav-link">Gallery</Link>
          </NavItem>
          <NavItem>
            <Link to="/contact/" className="nav-link">Contact</Link>
          </NavItem>
        </Nav>
      </Collapse>
    </Container>
  </Navbar>
}

export default NavigationBar
