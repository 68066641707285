import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
      } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
  }

  return <Helmet title={seo.title} titleTemplate={titleTemplate} >
    <meta name="description" content={seo.description}/>
    <meta property="og:url" content={seo.siteUrl} />
    <meta property="og:title" content={seo.title} />
    <meta property="og:description" content={seo.description} />
    <html lang="en" />
  </Helmet>
}

export default SEO

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl
            }
        }
    }
`
