import React from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"

const StyledSignature = styled.div.attrs({
  className: "section_footer__copyright",
})`
margin-top: 0;
a{
color: #ff6f61;
}
`

const Footer = () => <footer className="section section_footer">
  <Container>
    <Row>
      <Col sm={4}>
        <h5 className="section_footer__heading">
          About Us
        </h5>
        <p>
          El Humero is the first traditional Colombian-style barbecue restaurant in New Zealand.
        </p>
      </Col>
      <Col sm={4}>
        <h5 className="section_footer__heading">
          Contact info
        </h5>
        <ul className="section_footer__info">
          <li>
            <i className="fas fa-map-marker-alt"/> 3/40 Hurstmere Road, Takapuna, Auckland
          </li>
          <li>
            <i className="fas fa-phone"/> +64 9 4899973
          </li>
          <li>
            <i className="far fa-envelope"/> <a href="mailto:contact@elhumero.co.nz.com">contact@elhumero.co.nz</a>
          </li>
        </ul>
      </Col>
      <Col sm={4}>
        <h5 className="section_footer__heading">
          Opening hours
        </h5>
        <div className="section_footer__open">
          <div className="section_footer__open__days">Monday</div>
          <div className="section_footer__open__time">Closed</div>
          <div className="section_footer__open__days">Tuesday</div>
          <div className="section_footer__open__time">Closed</div>
          <div className="section_footer__open__days">Wednesday - Sunday</div>
          <div className="section_footer__open__time">5:00 PM - 9:00 PM</div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <div className="section_footer__copyright">
          &copy; El Humero Limited. All rights reserved.
        </div>
        <StyledSignature>
          Made with love by <a href="https://fullstackconsulting.co.nz/">Full Stack Consulting</a>
        </StyledSignature>
      </Col>
    </Row>
  </Container>
</footer>

export default Footer
