import React from "react"
import "./../styles/theme.css"
import NavigationBar from "./navigationBar"
/* eslint-disable react/jsx-pascal-case */
import SEO from "./seo"
import Footer from "./footer"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const LayoutPage = ({ children }) =>
  <>
    <SEO/>
    <NavigationBar/>
    {children}
    <Footer/>
  </>

export default LayoutPage;

